<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

/* 隐藏垂直滚动条 */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
}

/* 隐藏水平滚动条 */
body::-webkit-scrollbar-horizontal {
  height: 0.5em;
}

body::-webkit-scrollbar-thumb-horizontal {
  background: transparent;
}




</style>
