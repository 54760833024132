import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/view/Home";
import P_Detail from "@/view/P_Detail";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/home"
    }
    ,
    {
        path: '/home',
        component: Home
    },
    {
        path: "/p_detail",
        component: P_Detail
    }
]

const router = new VueRouter({
    // mode: 'history',
    // 把常量routes放进来
    routes
})

// 导出router
export default router
