<template>
  <div class="home_container">
    <header id="header">
      <div class="logo">
        <div>
          <a href="/" class="app-header-logo">
          </a>
        </div>
        <div class="content">
          <h4>OpenCoCo International Trade Co., Ltd</h4>
          <h5>湖北欧可国际贸易有限公司</h5>
        </div>
      </div>
      <nav>
        <ul>
          <li><a id="about-link" @click.prevent="toTopic('#about')">About Us</a></li>
          <li><a id="products-link" @click.prevent="toTopic('#products')">Product Showcase</a></li>
          <li><a id="services-link" @click.prevent="toTopic('#services')">Services</a></li>
          <li><a id="contact-link" @click.prevent="toTopic('#contact')">Contact Us</a></li>
        </ul>
      </nav>
    </header>

    <main>
      <section id="hero">
        <h2 id="hero-title">欢迎来到电商公司</h2>
        <p id="hero-description">我们提供最优质的产品和服务。</p>
      </section>

      <section id="about" class="question">
        <h2 id="about-title">关于我们</h2>
        <p id="about-description">我们是一家致力于为客户提供最佳购物体验的电商公司。</p>
      </section>

      <section id="products" class="question">
        <h2 id="products-title">产品展示</h2>
        <div class="product-grid">
          <!-- 产品卡片 -->
          <div class="product-item" >
            <div class="pro_image">
              <img src="../assets/1.jpg" alt=""/>
            </div>
            <h3 style="text-align: center">Velcro</h3>
          </div>
          <div class="product-item">
            <div class="pro_image">
              <img src="../assets/2.jpg" alt=""/>
            </div>
            <h3 style="text-align: center">Heat Transfer Stickers</h3>
          </div>
        </div>
      </section>

      <section id="services" class="question">
        <h2 id="services-title">服务</h2>
        <ul id="services-list">
          <li>全球配送</li>
          <li>安全支付</li>
          <li>24/7 客户支持</li>
        </ul>
      </section>

      <section id="contact" class="question">
        <h2 id="contact-title">联系我们</h2>
        <p id="contact-info">电话：123-456-7890</p>
        <p id="contact-email">邮箱：info@ecommerce.com</p>
      </section>
    </main>

    <footer>
      <p><span id="footer-name">OpenCoCo</span>&copy; All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home",

  mounted() {
    document.getElementById('about-link').innerText = 'About Us';
    document.getElementById('products-link').innerText = 'Product Showcase';
    document.getElementById('services-link').innerText = 'Services';
    document.getElementById('contact-link').innerText = 'Contact Us';
    document.getElementById('hero-title').innerText = 'Welcome to OpenCoco';
    document.getElementById('hero-description').innerText = ' A trusted leader in the import and export trade industry.';
    document.getElementById('about-title').innerText = 'About Us';
    document.getElementById('about-description').innerText = 'With years of experience and a global network of partners, we specialize in connecting businesses across borders, providing seamless solutions for international trade.';
    document.getElementById('products-title').innerText = 'Product Showcase';
    document.getElementById('services-title').innerText = 'Services';
    document.getElementById('services-list').innerHTML = '<li>Our services cover a wide range of industries, including electronics, textiles, paper products and printing, etc. </li><li>We pride ourselves on our commitment to quality, reliability, and customer satisfaction, <br/>ensuring that every transaction is handled with the utmost care and professionalism.</li><br><li style="list-style: disc; margin-top: -12px; margin-left: 15px">Global Shipping</li><li style="list-style: disc;margin-left: 15px">Secure Payment</li><li style="list-style: disc;margin-left: 15px">24/7 Customer Support</li>';
    document.getElementById('contact-title').innerText = 'Contact Us';
    document.getElementById('contact-info').innerText = 'Phone: +86 13530017189 (WhatsApp)';
    document.getElementById('contact-email').innerText = 'Email: cici.tuan77@gmail.com';
    document.getElementById('footer-name').innerText = 'OpenCoCo';
  },

  methods: {
    toTopic(idName){
      document.querySelector(idName).scrollIntoView();
    },
    toDetail() {
      this.$router.push("/p_detail")
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: currentColor;

}

.home_container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


header {
  /*position: fixed;*/
  width: 100%;
  background: rgba(255, 255, 255, 1); /* 半透明的白色背景 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  transition: background 0.3s;
  z-index: 1000;
  color: currentColor;
}


.app-header-logo {
  display: block;
  background: url("../assets/logo.png")no-repeat;
  /* 背景图片显示尺寸(放大/缩小背景图) */
  width: 80px;
  height: 83px;
  background-size: contain;    /* 通过百分比设置 */
}

.logo h1 {
  margin: 0;
  font-size: 24px;
  color: white;
}

nav {
  margin-right: 40px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
  cursor: pointer;
}

nav a {
  color: currentColor;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

nav a:hover {
  color: #0056b3;
}

main {
  padding: 70px 20px 20px; /* 预留出空间给固定的导航栏 */
}


.product-grid {
  display: flex;
}

.product-grid .product-item {
  width: 30%;
  height: 390px;
  margin: 0 40px;
  border: 1px solid #ccc;
}

#hero {
  margin-bottom: 40px;
  padding: 50px 20px;
  text-align: center;
}

section {
  background: rgba(255, 255, 255, 0.9); /* 使内容区背景稍微透出 */
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

section h2 {
  margin-bottom: 7px;
}

footer {
  text-align: center;
  padding: 20px;
  color: currentColor;
  border-radius: 10px;
  background: rgba(0 ,0, 0, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
}


#products-title {
  margin-bottom: 40px;
}


.product-item .pro_image {
  padding: 10px;
  text-align: center;
}

.product-item .pro_image img {
  width: 80%;
  height: 300px;
}

.logo {
  display: flex;
}
.logo .content {
 margin-top: 15px;
  margin-left: -5px;
}
</style>