<template>
  <div class="detail-container">
    <header id="header">
      <div class="logo">
        <a href="/" class="app-header-logo">
        </a>
      </div>
    </header>

  <main>
    <h1 class="product-title">Product Name</h1>
    <img class="product-image" src="product.jpg" alt="Product Image">

    <div class="product-details">
      <h2>Product Description</h2>
      <p>Here is a detailed description of the product, introducing its features and functions.</p>

      <h2>Features and Benefits</h2>
      <ul>
        <li>Feature One</li>
        <li>Feature Two</li>
        <li>Feature Three</li>
      </ul>

      <h2>Usage Instructions</h2>
      <p>Specific methods and precautions for using the product.</p>

      <h2>Pricing and Promotion Information</h2>
      <p class="price">Price: $99.00</p>
      <p class="promo">Limited-time Promotion: $20 Off</p>
    </div>

    <button class="buy-button">Buy Now</button>
  </main>
  </div>
</template>

<script>
export default {
  name: "P_detail"
}
</script>

<style scoped>
main {
  max-width: 800px;
  margin: 20px auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}

.product-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-details h2 {
  margin-top: 20px;
  font-size: 20px;
}

.price {
  font-size: 18px;
  color: red;
  font-weight: bold;
}

.promo {
  font-size: 16px;
  color: orange;
}

.buy-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.buy-button:hover {
  background-color: #218838;
}

header {
  /*position: fixed;*/
  width: 100%;
  background: rgba(255, 255, 255, 1); /* 半透明的白色背景 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  transition: background 0.3s;
  z-index: 1000;
  color: currentColor;
}


.app-header-logo {
  display: block;
  background: url("../assets/logo.png")no-repeat;
  /* 背景图片显示尺寸(放大/缩小背景图) */
  width: 352px;
  height: 83px;
  background-size: contain;    /* 通过百分比设置 */
}

.logo h1 {
  margin: 0;
  font-size: 24px;
  color: white;
}
</style>